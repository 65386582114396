export default {
  remove_from_my_selection: 'Remove from my selection',
  add_into_my_selection: 'Add to my selection',
  source: 'Record number',
  illustration_name: 'Illustration',
  main_record: 'Main record',
  artist: 'Artist',
  graphic_arts: 'Graphic arts',
  main_author: 'Author',
  publisher_details: 'Publisher data',
  show_more: 'Show more',
  notes: 'Notes',
  topic: 'Topic / genre',
  personal_name: 'Personal name',
  genre: 'Genre',
  subjects: 'Subjects',
  literature: 'Literature',
  links: 'Digital copies',
  catalog_link: 'Link to bibliography',
  exemplars: 'Exemeplars',
  permanent_link: 'Permanent link',
  show_less: 'Show less',
  data_about_the_plate: 'Data about the plate ',
  place_and_dimensions: 'Place and dimensions',
  scope: 'Scope',
  illustration_in: 'Illustration in',
  next_record: 'Next record',
  previous_record: 'Previous record',
  printer: 'Printer',
  publisher: 'Publisher',
  publishing_place: 'Publishing place',
  publishing_place_or_origin: 'Publishing place / origin',
  bibliographic_antecedent: 'Bibliographic antecedent',
  author: 'Author',
  presumed_author: 'Presumed author',
  cartographer: 'Cartographer',
  engraver: 'Engraver',
  etcher: 'Etcher',
  illustrator: 'Illustrator',
  metal_engraver: 'Metal engraver',
  patron: 'Patron',
  woodcarver: 'Woodcarver',
  other_role: 'Other role',
  mirador_link: 'IIIF manifest',
  manifest: 'manifest',
  keywords: 'Keywords',
}
