export default {
  remove_from_my_selection: 'Odebrat z mého výběru',
  add_into_my_selection: 'Přidat do mého výběru',
  source: 'Číslo záznamu',
  illustration_name: 'Název',
  main_record: 'Hlavní záznam',
  artist: 'Umělec',
  graphic_arts: 'Výtvarná technika',
  main_author: 'Autor',
  publisher_details: 'Nakladatelské údaje',
  show_more: 'Zobrazit více',
  notes: 'Poznámky',
  topic: 'Téma / žánr',
  personal_name: 'Osobní jméno',
  genre: 'Žánr',
  subjects: 'Předměty',
  literature: 'Literatura',
  links: 'Digitální kopie',
  catalog_link: 'Odkaz na knihovědu',
  exemplars: 'Exempláře',
  permanent_link: 'Permanentní odkaz',
  show_less: 'Zobrazit méně',
  data_about_the_plate: 'Údaje o štočku ',
  place_and_dimensions: 'Umístění a rozměr',
  scope: 'Rozsah',
  illustration_in: 'Ilustrace v',
  next_record: 'Další záznam',
  previous_record: 'Předchozí záznam',
  printer: 'Tiskař',
  publisher: 'Nakladatel',
  publishing_place: 'Místo vydání',
  publishing_place_or_origin: 'Místo vydání / vzniku',
  bibliographic_antecedent: 'Bibliografický předchůdce',
  author: 'Autor',
  presumed_author: 'Předpokládaný autor',
  cartographer: 'Kartograf',
  engraver: 'Rytec',
  etcher: 'Leptač',
  illustrator: 'Ilustrátor',
  metal_engraver: 'Rytec kovů',
  patron: 'Patron',
  woodcarver: 'Řezbář',
  other_role: 'Jiná role',
  mirador_link: 'IIIF manifest',
  manifest: 'manifest',
  keywords: 'Klíčová slova',
}
